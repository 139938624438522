<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" @click="$Jump('/my/goods/goods_info/add')">添加商品</el-button>
			</div>
			<div class="Right">
				<span>
					<el-select placeholder="商品状态" v-model="Search.Status">
						 <el-option label="全部商品" :value="-1"></el-option>
						 <el-option label="待上架" :value="50"></el-option>
						 <el-option label="上架中" :value="60"></el-option>
						 <el-option label="补货中" :value="70"></el-option>
						 <el-option label="已下架" :value="80"></el-option>
					  </el-select>
				</span>
				<span style="margin-left: 10px;margin-right: 10px;">
					<el-input placeholder="请输入查询内容" v-model="Search.Title"></el-input>
				</span>
				<span>
					<el-button type="primary" @click="GetList(Page)">搜索</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">商品图</div>
				<div class="C2">商品名</div>
				<div class="C3">商品状态</div>
				<div class="C4">价格</div>
				<div class="C5">发布时间</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in GoodsList" :key="item.Id">
				<li>
					<div class="C1"><img v-if="item.Thumb != ''" :src="item.Thumb" /></div>
					<div class="C2">{{item.Title}}</div>
					<div class="C3">
						
						{{item.StatusName}}
						
					</div>
					<div class="C4">
						￥{{item.Price}} /<i style="text-decoration: line-through;">{{item.OriginalPrice}}</i>
					
					</div>
					<div class="C5">{{item.CreatedAt}}</div>
					<div class="C6">
						<el-button size="mini" v-if="item.Status == 50" type="danger" round @click="Status55Check(item)">申请上架</el-button>
						<el-button size="mini" v-if="item.Status == 55" type="primary" round @click="Status50Check(item)">取消申请</el-button>
						<el-button size="mini" v-if="item.Status >= 60" type="primary" round @click="Status50Check(item)">下架</el-button>
						<el-button size="mini" round @click="$Jump('/my/goods/goods_info/'+item.Id)">管理</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'GoodsList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Status:-1,//全部
				  Id:'',//商品ID
				  Title:'',//关键词
			  },
			  GoodsList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.StartSeaching()
	  },
	  methods:{
		  Status55Check(_goods){
			  this.$confirm('是否确认发起商品上架申请?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
				}).then(() => {
					this.StatusChange(_goods,55)
				})
		  			  
		  },
		  Status50Check(_goods){

			  this.$confirm('商品下架操作中, 是否确认继续?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			    }).then(() => {
					this.StatusChange(_goods,50)
			    })
			  
		  },
		  StatusChange(_goods,_status){
			  let data = {
			  	Service:'Goods',
			  	Class: 'Goods',
			  	Action: 'Update',
			  	Id:_goods.Id,
			  	Status:_status,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	if(res.ErrorId != 0){
					this.$message.error(res.Msg)
					return
				}
				if(_status == 50){
					this.$message.success('下架成功，如有需要可重新上申请上架')
				}else if(_status == 55){
					this.$message.success('申请成功，审核通过后将自动上架')
				}
				 this.GetList(this.Page)
			  })
		  },
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSeaching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Goods',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
					ShopId:this.$store.getters.getShopId,
					Title:this.Search.Title,
					Id:this.Search.Id,
					Status:this.Search.Status,
					IsShopCenter:1,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.GoodsList = res.Data.GoodsList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Goods',
		  			Class: 'Goods',
		  			Action: 'Count',
					ShopId:this.$store.getters.getShopId,
					Title:this.Search.Title,
					Id:this.Search.Id,
					Status:this.Search.Status,
					IsShopCenter:1,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  					this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.Brand .List{
}
.C1,.C2,.C3,.C4,.C5,.C6{
	width: 150px;
}
.C1{
	padding-left: 10px;
	width: 60px;
	margin-right: 20px;
}
.C1 img{
	width: 60px;
}
.C2{
	flex: 1;
	margin-right: 10px;
}
.C4 i{
	font-style: normal;
	color: rgba(0,0,0,0.3);
	margin-left: 5px;
}
.C5{
	color: rgba(0,0,0,0.3);
}
.C6{
	padding-right: 10px;
	text-align: right;
}
.Brand .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.Brand .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.Brand .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
